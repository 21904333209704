/* Template Name: Qilturo
    Author: WaterDesign
    Email: hpetreh@gmail.com
   File Description: Main JS file of the template
*/

!(function ($) {
  "use strict";

  var SM = function () { };
  var current_index = 0;
  var active_slide_count = 0;
  var interval;
  var stats_already_scrolled = true;
  var reasons_already_scrolled = true;
  var app_screen_already_scrolled = true;
  var time_already_scrolled = true;
  var clicked_dot = false;
  var sliding_delay = 7000;
  var profil_links = [];
  var profil_cords = [{}];

  (SM.prototype.initStickyMenu = function () {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 170) {
        $(".menu-header").css("position", "fixed");
      } else {
        $(".menu-header").css("position", "relative");
      }
    });
  }),
    (SM.prototype.initMobileMenu = function () {
      const netflix_open_btn = document.querySelector(".netflix-open-btn");
      const netflix_close_btn = document.querySelector(".netflix-close-btn");
      const netflix_nav = document.querySelectorAll(".netflix-nav");

      if (netflix_open_btn == null) return;
      netflix_open_btn.addEventListener("click", () => {
        $(".mobile-top-menu").css("z-index", "0");
        netflix_nav.forEach((nav_el) => {
          nav_el.classList.add("visible");
        });
      });

      netflix_close_btn.addEventListener("click", () => {
        $(".mobile-top-menu").css("z-index", "100000000000");
        netflix_nav.forEach((nav_el) => {
          nav_el.classList.remove("visible");
        });
      });
    }),
    (SM.prototype.initSmoothLink = function () {
      $(".smooth-link").on("click", function (event) {
        event.preventDefault();

        var $anchor = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $($anchor.attr("href")).offset().top - 100,
            },
            1500,
            "easeInOutExpo"
          );
        event.preventDefault();
      });

      $(".navbar-nav a").on("click", function (event) {
        var $anchor = $(this);
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $($anchor.attr("href")).offset().top - 50,
            },
            1500,
            "easeInOutExpo"
          );
        event.preventDefault();
      });
    }),
    (SM.prototype.initBacktoTop = function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $(".back-to-top").fadeIn();
        } else {
          $(".back-to-top").fadeOut();
        }
      });
      $(".back-to-top").click(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          1000
        );
        return false;
      });
    }),
    (SM.prototype.initTestimonialCarroussel = function () {
      var owl = $(".owl-carousel");
      owl.owlCarousel({
        autoPlay: 100,
        nav: true,
        items: 1,
      });

      $(".owl-carousel .testi-profil").each(function (index, element) {
        // element == this
        var link = $(this).attr("src");
        var anim_index = 1;
        if (index == 0 || index == 3 || index == 6 || index == 9) {
          anim_index = 1;
        }
        if (index == 1 || index == 4 || index == 7 || index == 10) {
          anim_index = 2;
        }
        if (index == 2 || index == 5 || index == 8 || index == 11) {
          anim_index = 3;
        }

        profil_links.push({
          link: link,
          id: index,
        });
        setTimeout(() => {
          $(".carousel-container .floating-profiles").append(
            '<img src="' +
            link +
            '" id="fl_img_' +
            index +
            '" position="' +
            index +
            '" class="floating-profiles-img particlemove' +
            anim_index +
            '" alt=""></img>'
          );
        }, 500);
      });
      console.log(profil_links);

      $(document).on("click", ".floating-profiles-img", function (event) {
        var id = event.target.id;
        var position = $("#" + id).attr("position");
        console.log(position);
        owl.trigger("to.owl.carousel", position);
      });

      $("").click(function (e) {
        e.preventDefault();
      });
    }),
    (SM.prototype.initStatCounters = function () {
      $(".stats-digits").on("scrollSpy:enter", function () {
        $(".countfect").each(function () {
          var $this = $(this);
          var countTo = $this.attr("data-num");
          var delayTo = +$this.attr("delay");
          if (!delayTo) {
            delayTo = 8000;
          }
          $({
            countNum: $this.text(),
          }).animate(
            {
              countNum: countTo,
            },
            {
              duration: delayTo,
              easing: "linear",
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
              },
            }
          );
        });
      });

      $(".stats-digits").scrollSpy();
    }),
    (SM.prototype.initVideoPopup = function () {
      $(".video-placeholder").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
      });
    }),
    (SM.prototype.initTeamCarousel = function () {
      //recover the carousel class
      var owl = $(".team-carousel-container").children(".owl-carousel");
      owl.owlCarousel({
        autoplay: true,
        nav: false,
        items: 4,
        dots: true,
        loop: true,
        center: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
          1400: {
            items: 4,
          },
        },
      });
    }),
    (SM.prototype.initPartenairesCarousel = function () {
      //recover the carousel class
      var owl = $(".partenaires-carousel-container").children(".owl-carousel");
      owl.owlCarousel({
        autoplay: true,
        nav: false,
        items: 3,
        dots: true,
        loop: true,
        center: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 2,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 4,
          },
          1400: {
            items: 4,
          },
        },
      });
    }),
    (SM.prototype.initFAQ = function () {
      var icons = {
        header: "ui-icon-circle-plus",
        activeHeader: "ui-icon-circle-minus",
      };
      $("#accordion").accordion({
        icons: icons,
        heightStyle: "content",
      });
      $("#toggle")
        .button()
        .on("click", function () {
          if ($("#accordion").accordion("option", "icons")) {
            $("#accordion").accordion("option", "icons", null);
          } else {
            $("#accordion").accordion("option", "icons", icons);
          }
        });
    }),
    (SM.prototype.initPhoneInput = function () {
      $(".intl-tel-input").each(function () {
        var input = this;
        var errorMsg = $(this).parent().children(".error-msg");
        var validMsg = $(this).parent().children(".valid-msg");

        console.log(validMsg.html());
        console.log(errorMsg.html());

        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = [
          "Numero invalide",
          "Indicatif invalide",
          "Trop court",
          "Trop long",
          "Numero invalide",
        ];

        // initialise plugin
        var iti = window.intlTelInput(input, {
          utilsScript: "../intl-tel-input/js/utils.js?1638200991544",
        });

        var reset = function () {
          $(input).removeClass("error");
          errorMsg.html("");
          errorMsg.removeClass("d-block");
          validMsg.removeClass("d-block");
        };

        // on blur: validate
        input.addEventListener("blur", function () {
          reset();
          if (input.value.trim()) {
            if (iti.isValidNumber()) {
              //validMsg.addClass("d-block");
            } else {
              $(input).addClass("error");
              var errorCode = iti.getValidationError();
              if (errorCode == -99) errorCode = 0;
              errorMsg.html(errorMap[errorCode]);
              errorMsg.addClass("d-block");
            }
          }
        });

        // on keyup / change flag: reset
        input.addEventListener("change", reset);
        input.addEventListener("keyup", reset);
        window.intlTelInput(this, {
          // any initialisation options go here
        });
      });
    }),
    (SM.prototype.initArticleCarousel = function () {
      //recover the carousel class
      var owl = $(".article-content").children(".owl-carousel");
      owl.owlCarousel({
        autoplay: true,
        nav: false,
        items: 3,
        dots: true,
        loop: false,
        margin: 20,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
          1400: {
            items: 3,
          },
        },
      });

      $(".article-content")
        .children(".gallery")
        .magnificPopup({
          delegate: "a",
          type: "image",
          tLoading: "Chargment #%curr%...",
          mainClass: "mfp-img-mobile",
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
            tCounter: '<span class="mfp-counter">%curr% sur %total%</span>' // markup of counter
          },
          image: {
            tError:
              '<a href="%url%">L\'image #%curr%</a> n\'a pas pu être chargée.',
            titleSrc: function (item) {
              return (
                item.el.attr("title") + ""
              );
            },
          },
        });
    }),
    (SM.prototype.initHeroCarousel = function () {
      //recover the carousel class
      $(".hero-carousel-container")
        .children(".will-be-owl-carousel")
        .addClass("owl-carousel");
      $(".hero-carousel-container")
        .children(".owl-carousel")
        .removeClass("will-be-owl-carousel");
      var owl = $(".hero-carousel-container").children(".owl-carousel");
      var carousel = owl.owlCarousel({
        autoplay: true,
        nav: true,
        items: 1,
        dots: true,
        loop: true,
        center: true,
      });
      //console.log('carousel', carousel);

    }),
    (SM.prototype.initProduitsCarousel = function () {
      //recover the carousel class
      $(".produits-carousel-container")
        .children(".will-be-owl-carousel")
        .addClass("owl-carousel");
      $(".produits-carousel-container")
        .children(".owl-carousel")
        .removeClass("will-be-owl-carousel");
      var owl = $(".produits-carousel-container").children(".owl-carousel");
      owl.owlCarousel({
        autoplay: true,
        nav: true,
        items: 4,
        dots: true,
        loop: true,
        center: true,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
          1400: {
            items: 4,
          },
        },
      });
    }),
    (SM.prototype.initHeroIllHover = function () {
      $(".hero-carousel .illustration").hover(
        function () {
          $(".ileira-logo").animate(
            {
              right: "30px",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
          $(".ileira-logo-small").animate(
            {
              left: "20%",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
          $(".hero-image-to-clip-img ").animate(
            {
              marginLeft: "50px",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
        },
        function () {
          $(".ileira-logo").animate(
            {
              right: "0",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
          $(".ileira-logo-small").animate(
            {
              left: "30%",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
          $(".hero-image-to-clip-img ").animate(
            {
              marginLeft: "0",
            },
            {
              duration: 300,
              easing: "easeOutQuad",
            }
          );
        }
      );
    }),
    (SM.prototype.initForms = function () {
      $(":input").focusout(function (e) {
        e.preventDefault();
        $(this).attr("dirty", "true");
      });

      $(".password-visibility").click(function (e) {
        e.preventDefault();
        var current_state = $(this).attr("src");
        console.log(current_state);
        if (current_state.includes("show")) {
          $(this).attr("src", current_state.replace("show", "hide"));
          $("#" + $(this).attr("visivility-for")).attr("type", "password");
        } else {
          $(this).attr("src", current_state.replace("hide", "show"));
          $("#" + $(this).attr("visivility-for")).attr("type", "text");
        }
      });

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName("needs-validation");
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    }),
    (SM.prototype.init = function () {
      /* this.initForms();
    this.initAudioPlayer();
    this.initReasonsIllHover();
    this.initHIWBoxHover();
    this.initTimeIllHover();
    this.initPhoneAppHover();
    this.initTestimonialCarroussel();
    this.initHeroIllInit(); */
      this.initPhoneInput();
      this.initArticleCarousel();
      this.initVideoPopup();
      this.initFAQ();
      this.initStatCounters();
      this.initProduitsCarousel();
      this.initHeroCarousel();
      this.initTeamCarousel();
      this.initPartenairesCarousel();
      this.initHeroIllHover();
      this.initMobileMenu();
      this.initStickyMenu();
      this.initSmoothLink();
      this.initBacktoTop();

      $(window).focus(function () {
        focus = true;
      });

      $(window).blur(function () {
        focus = false;
      });

      
    }),
    //init
    ($.SM = new SM()),
    ($.SM.Constructor = SM);
})(window.jQuery),
  //initializing
  (function ($) {
    "use strict";
    $.SM.init();
  })(window.jQuery);
